<template>
  <div class="modal-card">
    <section class="modal-card-body" :class="{'loaded': ! isLoading}">
      <hero-bar v-if="plan" class="mb-10">
        {{ plan.name }} - {{ $t(plan.type) }} - {{ plan.time }}
        <h1 slot="right">
          {{ readableDate }}
        </h1>
      </hero-bar>
      <div v-if="plan">
        <card-component class="mb-10">
          <div class="totalRegisterCount">
            Toplam Kişi: <span class="tag">{{ registers.length - trashedRegisters.length }} <span class="has-text-danger">({{ trashedRegisters.length }})</span></span>
          </div>
          <div class="columns no-wrap mt-5">
            <div class="column one">
              <b-tabs
                v-model="activeTab"
                :animated="false"
                :destroy-on-hide="true"
                type="is-boxed"
              >
                <b-tab-item label="Liste" icon="table-large">
                  <b-table
                    :data="groupedRegisters"
                    checkbox-position="right"
                    :row-class="(row, index) => {
                      let classes = row.items.length ? 'grouped-row' : 'ungrouped-row'
                      if (row.trashed) {
                        classes += ' has-background-danger-light has-text-grey'
                      }
                      return classes
                    }"
                    :is-row-checkable="(row) => !row.items.length && !row.trashed"
                    :checked-rows.sync="checkedRows"
                    checkable
                    sticky-header
                    hoverable
                    draggable
                    detailed
                    detail-key="id"
                    :opened-detailed="openedDetailed"
                    custom-detail-row
                    :show-detail-icon="true"
                    class="no-wrap"
                    @details-open="detailsOpened"
                    @details-close="detailsClosed"
                    @dragstart="dragstart"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                  >
                    <b-table-column v-slot="props" custom-key="actions" width="90" class="is-actions-cell">
                      <template v-if="!props.row.items.length">
                        <!--<b-button
                          outlined
                          active
                          size="is-small"
                          class="mr-5 cursor-move"
                          icon-left="cursor-move"
                        />-->
                        <b-tooltip
                          v-if="props.row.auto_assigned_at"
                          :label="props.row.auto_assigned_at"
                          type="is-dark"
                        >
                          <b-button
                            outlined
                            size="is-small"
                            class="is-info mr-5 only-icon"
                            icon-left="information"
                            style="cursor:auto;"
                          />
                        </b-tooltip>
                        <b-tooltip :label="props.row.trashed ? $t('canceled') : $t('cancelRequest')" type="is-dark" position="is-right">
                          <b-button
                            outlined
                            size="is-small"
                            class="button-green is-danger mr-5 only-icon"
                            icon-left="close-circle"
                            :disabled="props.row.trashed"
                            @click="!props.row.trashed && cancelRequest(props.row.id, false)"
                          />
                        </b-tooltip>
                        <b-button
                          outlined
                          size="is-small"
                          class="button-purble btn-details"
                          icon-left="arrow-right-bold"
                          @click="$parent.$parent.registerDetails(props.row.id)"
                        />
                      </template>
                    </b-table-column>

                    <b-table-column v-slot="props" label="ID" field="id" :visible="false">
                      <span>{{ props.row.id }}</span>
                    </b-table-column>

                    <b-table-column v-slot="props" :label="$t('carTxt')" field="car.plate">
                      <span v-if="props.row.car" class="tag">{{ props.row.car.plate }}</span>
                    </b-table-column>

                    <b-table-column v-slot="props" :label="$t('driver')">
                      <span v-if="props.row.driver">
                        <b-tooltip
                          :label="props.row.driver.name"
                          type="is-dark"
                          position="is-bottom"
                        >
                          {{ props.row.driver.name | limitShow }}
                        </b-tooltip>
                      </span>
                    </b-table-column>

                    <b-table-column v-slot="props" :label="$t('fullName')" field="user.name">
                      <b-tooltip
                        :label="props.row.user.name"
                        type="is-dark"
                        position="is-bottom"
                      >
                        {{ props.row.user.name | limitShow }}
                      </b-tooltip>
                    </b-table-column>

                    <b-table-column v-slot="props" :label="$t('phone')" field="phone">
                      <a :href="`tel:${props.row.user.phone}`">{{ props.row.user.phone | phone }}</a>
                    </b-table-column>

                    <b-table-column v-slot="props" :label="$t('address')" field="address">
                      <b-tooltip
                        :label="props.row.address"
                        type="is-dark"
                        position="is-bottom"
                      >
                        {{ props.row.address | formatAddress }}
                      </b-tooltip>
                    </b-table-column>

                    <template slot="detail" slot-scope="props">
                      <tr
                        v-for="(item, index) in props.row.items"
                        :key="item.id"
                        :class="{'has-background-danger-light has-text-grey': item.trashed}"
                        draggable="true"
                        @dragstart="dragstart({event: $event, row: item, index})"
                        @dragover="dragover({event: $event, row: item, index})"
                        @dragleave="dragleave({event: $event, row: item, index})"
                        @drop="drop({event: $event, row: item, index})"
                      >
                        <td />
                        <td
                          custom-key="actions"
                          width="90"
                          class="is-actions-cell"
                          :class="{'has-text-right': !item.auto_assigned_at}"
                        >
                          <b-tooltip
                            v-if="item.auto_assigned_at"
                            :label="item.auto_assigned_at"
                            type="is-dark"
                          >
                            <b-button
                              outlined
                              size="is-small"
                              class="is-info mr-5 only-icon"
                              icon-left="information"
                              style="cursor:auto;"
                            />
                          </b-tooltip>
                          <b-button
                            outlined
                            active
                            size="is-small"
                            class="mr-5 cursor-move"
                            icon-left="cursor-move"
                          />
                          <b-tooltip :label="item.trashed ? $t('canceled') : $t('cancelRequest')" type="is-dark" position="is-right">
                            <b-button
                              outlined size="is-small"
                              class="button-green is-danger mr-5 only-icon"
                              icon-left="close-circle"
                              :disabled="item.trashed"
                              @click="!item.trashed && cancelRequest(item.id, false)"
                            />
                          </b-tooltip>
                          <b-button
                            outlined
                            size="is-small"
                            class="button-purble btn-details"
                            icon-left="arrow-right-bold"
                            @click="$parent.$parent.registerDetails(item.id)"
                          />
                        </td>
                        <td><span class="tag">{{ item.car.plate }}</span></td>

                        <td v-if="item.driver">{{ item.driver.name | limitShow }}</td>
                        <td v-else>-</td>

                        <td>{{ item.user.name | limitShow }}</td>
                        <td>{{ item.user.phone | phone }}</td>
                        <td>{{ item.address | formatAddress }}</td>
                        <td>
                          <b-checkbox v-model="checkedRowsCustom" :native-value="item" />
                        </td>
                      </tr>
                    </template>

                    <template #bottom-left>
                      <b>{{ $t('selected') }}</b>: {{ checkedRows.length + checkedRowsCustom.length }}
                    </template>
                  </b-table>
                </b-tab-item>

                <b-tab-item label="Harita" icon="google-maps">
                  <LMap
                    ref="map"
                    :center="center"
                    :zoom="mapZoom"
                    class="map"
                    @update:zoom="updateZoom"
                  >
                    <LControl position="bottomleft">
                      <b-button size="is-small" icon-right="crosshairs-gps" @click="resetZoom" />
                    </LControl>
                    <LTileLayerGoogleMutant />
                    <LMarker
                      :lat-lng="coordinateToArray(plan.start_location)"
                      :icon="markers.plan"
                    >
                      <LTooltip>{{ plan.name }}</LTooltip>
                    </LMarker>
                    <MarkerCluster
                      ref="mcluster"
                      @ready="readyMarkerCluster"
                    >
                      <LMarker
                        v-for="(register, index) in registersWithoutTrashed"
                        :key="`marker-${index}`"
                        :lat-lng="coordinateToArray(register.location)"
                        :icon="markerIcon(register)"
                        @click="markerClick(register)"
                      >
                        <LTooltip>
                          {{ register.user.name }}
                          <span v-if="register.driver">
                            <br> {{ register.driver.name }}
                            <span v-if="register.car"> / {{ register.car.plate }} </span>
                          </span>
                        </LTooltip>
                      </LMarker>
                    </MarkerCluster>
                  </LMap>

                  <b-notification :closable="false" class="mt-10">
                    <b>{{ $t('selected') }}</b>: {{ checkedRows.length }}
                  </b-notification>
                </b-tab-item>
              </b-tabs>
            </div>

            <div v-if="checkPermission('shuttle_set_driver')" class="column two">
              <div class="border-select">
                <b-field :label="$t('driver')">
                  <b-autocomplete
                    ref="driverInput"
                    v-model="fakeDriver"
                    field="name"
                    :data="drivers"
                    :placeholder="$t('chooseDriver')"
                    :loading="isDriverFetching"
                    @typing="getAsyncDriverData"
                    @select="selectDriver"
                  >
                    <template slot-scope="props">
                      <div class="media">
                        <div class="media-content" :class="{ 'has-text-danger': !props.option.has_shift }">
                          <b>{{ props.option.name }}</b>
                          <br>
                          <small>
                            {{ props.option.phone | phone }}
                          </small>
                        </div>
                      </div>
                    </template>
                  </b-autocomplete>
                </b-field>

                <b-field :label="$t('carTxt')">
                  <b-autocomplete
                    v-model="fakeCarPlate"
                    field="plate"
                    :data="cars"
                    :placeholder="$t('chooseCar')"
                    :loading="isCarFetching"
                    @typing="getAsyncCarData"
                    @select="selectCar"
                  >
                    <template slot-scope="props">
                      <span>
                        {{ props.option.plate }}
                        <span class="emptyText">({{ props.option.brand }} - {{ props.option.model }})</span>
                      </span>
                    </template>
                  </b-autocomplete>
                </b-field>

                <b-field>
                  <b-button
                    type="is-purble"
                    class="is-fullwidth"
                    :class="{'is-loading': setIsLoading}"
                    :disabled="saveButtonDisabled"
                    @click="setDriverAndCar"
                  >
                    {{ $t('save') }}
                  </b-button>
                </b-field>
              </div>

              <b-table :data="rates" narrowed class="no-wrap mt-2">
                <b-table-column v-slot="props" :label="$t('plate')" field="plate" centered>
                  <span class="tag">
                    {{ props.row.plate }}
                  </span>
                </b-table-column>
                <b-table-column v-slot="props" :label="$t('seats')" field="seats" centered>
                  <span class="tag">
                    {{ props.row.seats }}
                  </span>
                </b-table-column>
                <b-table-column v-slot="props" :label="$t('passengerOccupancyRate')" field="occupancy_rate" centered>
                  <span class="tag" :class="{'is-danger': occupancyRate(props.row.count, props.row.seats) > 100}">
                    %{{ occupancyRate(props.row.count, props.row.seats) }}
                  </span>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </card-component>
      </div>
    </section>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import moment from 'moment'
import L from 'leaflet'
import { LMap, LMarker, LTooltip, LControl } from 'vue2-leaflet' // https://vue2-leaflet.netlify.app/components/LMap.html#demo

const rates = (registers) => {
  return registers.filter(register => register.car?.seats && !register.trashed).reduce((acc, { car }) => {
    const index = acc.findIndex((a) => a.plate === car.plate)

    if (index > -1) {
      acc[index].count++
    } else {
      acc.push({
        plate: car.plate,
        seats: car.seats,
        count: 1,
      })
    }

    return acc
  }, [])
}

const groupRegisters = (registers) => {
  // group shuttle registers by car
  const grouped = registers.reduce((acc, register) => {
    const index = acc.findIndex((a) => {
      if (a.car && register.car) {
        return a.car.id === register.car.id
      }

      return false
    })

    if (index > -1) {
      acc[index].items.push(register)
    } else {
      acc.push({
        ...register,
        items: [register],
      })
    }

    return acc
  }, [])

  return grouped.map(register => {
    if (register.items.length < 2) {
      register.items = []
      return register
    }

    const trashed = register.items.filter((r) => !r.trashed).length === 0

    register = {
      ...register,
      user: {},
      address: '',
      trashed,
    }

    // sort shuttle register items by `sort_order`
    register.items.sort((a, b) => a.sort_order - b.sort_order)

    return register
  })
}

export default {
  name: 'ShuttleDayDetails',
  components: {
    HeroBar: () => import('./HeroBar'),
    CardComponent: () => import('./CardComponent'),
    LTileLayerGoogleMutant: () => import('vue2-leaflet-googlemutant'), // https://github.com/jperelli/vue2-leaflet-googlemutant
    MarkerCluster: () => import('vue2-leaflet-markercluster'), // https://github.com/jperelli/vue2-leaflet-markercluster
    LMap,
    LMarker,
    LTooltip,
    LControl,
  },
  props: {
    shuttleId: {
      type: Number,
      default: null
    },
    registerDate: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      registers: [],
      plan: null,
      isLoading: true,
      setIsLoading: false,
      checkedRows: [],
      checkedRowsCustom: [],
      isDriverFetching: false,
      isCarFetching: false,
      drivers: [],
      cars: [],
      rates: [],
      driverId: null,
      carId: null,
      fakeDriver: '',
      fakeCarPlate: '',
      mapZoom: 10,
      markers: {
        plan: L.icon({
          iconUrl: '/img/markers/plan.png',
          iconSize: [48, 48],
          iconAnchor: [48 / 2, 48],
          tooltipAnchor: [16, -24],
        }),
        okey: L.icon({
          iconUrl: '/img/markers/okey.png',
          iconSize: [38, 38],
          iconAnchor: [28 / 2, 28],
          tooltipAnchor: [16, -16],
        }),
        normal: L.icon({
          iconUrl: '/img/markers/normal.png',
          iconSize: [38, 38],
          iconAnchor: [28 / 2, 28],
          tooltipAnchor: [16, -16],
        }),
        selected: L.icon({
          iconUrl: '/img/markers/selected.png',
          iconSize: [38, 38],
          iconAnchor: [28 / 2, 28],
          tooltipAnchor: [16, -16],
        }),
      },
      draggingRow: null,
      draggingRowIndex: null,
      openedDetailed: [],
      activeTab: 0,
      center: this.shuttleCenterLocation,
    }
  },
  computed: {
    readableDate () {
      return moment(this.registerDate).locale(this.$i18n.locale).format('DD MMMM YYYY dddd')
    },
    saveButtonDisabled () {
      return (!this.driverId && !this.carId) || (this.checkedRows.length === 0 && this.checkedRowsCustom.length === 0)
    },
    shuttleCenterLocation () {
      return this.coordinateToArray(this.plan.start_location)
    },
    groupedRegisters () {
      return groupRegisters(this.registers)
    },
    trashedRegisters () {
      return this.registers.filter((register) => register.trashed)
    },
    registersWithoutTrashed () {
      return this.registers.filter((register) => !register.trashed)
    },
    // mapZoom () {
    //   return this.$refs.map.mapObject.getZoom()
    // }
  },
  watch: {
    fakeDriver (n) {
      if (n == '') {
        this.driverId = null;
      }
    },
    fakeCarPlate (n) {
      if (n == '') {
        this.carId = null;
      }
    },
  },
  mounted () {
    this.showSpinner()
    this.getDetails()
  },
  methods: {
    detailsOpened ({ id }) {
      this.openedDetailed = this.openedDetailed.filter(openedId => openedId === id)
    },
    detailsClosed ({ items }) {
      const ids = items.map(item => item.id)
      this.openedDetailed = this.openedDetailed.filter(openedId => !ids.includes(openedId))
    },
    dragstart ({ event, row, index }) {
      this.draggingRow = row
      this.draggingRowIndex = index
      event.dataTransfer.effectAllowed = 'copy'
    },
    dragover ({ event }) {
      event.dataTransfer.dropEffect = 'copy'
      event.target.closest('tr').classList.add('is-selected')
      event.preventDefault()
    },
    dragleave ({ event }) {
      event.target.closest('tr').classList.remove('is-selected')
      event.preventDefault()
    },
    drop ({ event, index }) {
      if (this.draggingRow.car === null) {
        this.$buefy.toast.open({
          message: 'Sıralama yapmak için lütfen araç atayınız.',
          type: 'is-danger',
          queue: false,
        })

        event.target.closest('tr').classList.remove('is-selected')

        return
      }

      if (this.draggingRowIndex === index) {
        event.target.closest('tr').classList.remove('is-selected')

        return
      }

      this.$http.post('shuttle-registers/sort', {
        id: this.draggingRow.id,
        car_id: this.draggingRow.car.id,
        shuttle_id: this.shuttleId,
        register_date: moment(this.registerDate).format('YYYY-MM-DD'),
        sort_order: index + 1,
        with_trashed: 1,
      }).then(({ data }) => {
        groupRegisters(data.data).forEach(({ items }) => {
          if (items.find(item => item.id === this.draggingRow.id) && !this.openedDetailed.includes(items[0].id)) {
            this.openedDetailed.push(items[0].id)
          }
        })

        this.registers = data.data
      })

      event.target.closest('tr').classList.remove('is-selected')
    },
    occupancyRate (count, seats) {
      return parseFloat((100 * count / seats).toFixed(2))
    },
    getDetails () {
      const params = {
        shuttle_id: this.shuttleId,
        register_date: moment(this.registerDate).format('YYYY-MM-DD'),
        with_trashed: 1,
      };

      this.$http.get('shuttle-day-details', { params }).then(({ data }) => {
        this.plan = data.plan
        this.registers = data.registers
        this.rates = rates(data.registers)
        this.isLoading = false
        this.carId = null
        this.driverId = null
        this.fakeDriver = ''
        this.fakeCarPlate = ''
        this.checkedRows = []
        this.checkedRowsCustom = []
        this.setIsLoading = false
        }).catch(() => {
          this.$buefy.toast.open({
            message: this.$t('error'),
            type: 'is-warning',
            duration: 5000,
          })

          this.$parent.close()
        }).finally(() => {
          this.hideSpinner()

          document.querySelectorAll('.grouped-row').forEach(item => item.removeAttribute('draggable'))
        })
    },
    readyMarkerCluster () {
      const mapBounds = this.$refs.mcluster.mapObject.getBounds()
      this.$refs.map.mapObject.fitBounds(mapBounds, { maxZoom: this.mapZoom });
    },
    setDriverAndCar () {
      const ids = [
        ...this.checkedRows.map(row => row.id),
        ...this.checkedRowsCustom.map(row => row.id),
      ]

      const params = {
        ids,
        driver_id: this.driverId,
        car_id: this.carId,
      }

      this.setIsLoading = true

      this.$http.put('shuttle-register-update', params).then(({ data }) => {
        if (data.success) {
          this.getDetails()

          this.$buefy.toast.open({
            message: 'Başarıyla güncellendi.',
            type: 'is-success',
            duration: 2000,
          })
        }
      }).catch(() => {
        this.setIsLoading = false
        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-warning',
          duration: 5000,
        })
      })
    },
    getAsyncDriverData: debounce(function (name) {
      this.drivers = []

      if (!name.length) {
        return
      }

      this.isDriverFetching = true

      const date = moment(this.registerDate).format('YYYY-MM-DD').toString() + ' ' + this.plan.time

      this.$http.get('available-drivers', {
        params: { name, date: moment(date).toISOString(true) }
      }).then(({ data }) => {
        this.drivers = data.data
      }).catch(() => {
        this.drivers = []
      }).finally(() => {
        this.isDriverFetching = false
      })
    }, 500),
    getAsyncCarData: debounce(function (q) {
      this.cars = []

      q = q.trim()

      if (q.length === 0) {
        return
      }

      this.isCarFetching = true

      this.$http.get('list-cars', { params: { q } }).then(({ data }) => {
        this.cars = data
      }).catch(() => {
        this.cars = []
      }).finally(() => {
        this.isCarFetching = false
      })
    }, 500),
    selectDriver (driver) {
      if (!driver) {
        return
      }

      this.driverId = driver.id
      this.drivers = []

      /* if (driver.has_shift) {
        this.driverId = driver.id
        this.drivers = []

        return
      }

      this.$buefy.dialog.confirm({
        message: 'Seçilen sürücünün vardiyası bu iş için uygun değil, yine de atama yapmak istiyor musunuz?',
        confirmText: 'Evet',
        cancelText: 'Hayır',
        onConfirm: () => {
          this.driverId = driver.id
          this.drivers = []
        },
        onCancel: () => {
          this.fakeDriver = ''
          this.$refs.driverInput.focus()
        }
      }) */
    },
    selectCar (option) {
      if (option) {
        this.carId = option.id
        // this.fakeCarPlate = option.plate
        this.cars = []
      }
    },
    cancelRequest (id) {
      this.$buefy.dialog.confirm({
        message: 'Emin misiniz?',
        cancelText: this.$t('no'),
        confirmText: this.$t('yes'),
        type: 'is-danger',
        onConfirm: () => {
          this.$http.delete('shuttle-register-delete', { data: { id, all: false }}).then(() => {
            this.$buefy.toast.open({message: this.$t('success'), type: 'is-success'})
            this.getDetails();
          })
        }
      })
    },
    markerClick (register) {
      if (this.$_.includes(this.checkedRows, register)) {
        this.checkedRows = this.$_.reject(this.checkedRows, {id: register.id})
      } else {
        this.checkedRows.push(register)
      }
    },
    markerIcon (register) {
      if (this.$_.includes(this.checkedRows, register)) {
        return this.markers.selected
      } else {
        if (register.driver) {
          return this.markers.okey
        }

        return this.markers.normal
      }
    },
    updateZoom (zoom) {
      this.mapZoom = zoom
    },
    resetZoom () {
      this.$refs.map.setZoom(10)
      setTimeout(() => this.$refs.map.setCenter(this.plan.start_location.split(',')), 500)
    },
  },
}
</script>

<style lang="scss" scoped>
  .modal-card {
    width: 90vw !important;

    .modal-card-body {
      background-color: #f8f8f8;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.26s ease;

      .column.one {
        max-width: 70%;
        margin-top: 20px;
      }
      .column.two {
        max-width: 30%;
        margin-top: 90px;
      }
      h1 {
        font-size: 25px;
        font-weight: bold;
      }
      .totalRegisterCount {
        font-size: 18px;
        font-weight: bold;
      }
      .border-select {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 2px solid #f5f5f5;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        color: rgba(0,0,0,.7);
        padding: .5rem;

        &:before {
          background: #ffdd57;
          border-radius: 4px 4px 0 0;
          bottom: 100%;
          content: "Seçili Kullanıcılar İçin Ata";
          display: inline-block;
          font-size: 11px;
          font-weight: 700;
          left: -1px;
          padding: 4px 8px;
          position: absolute;
          text-transform: uppercase;
          vertical-align: top;
        }
      }
    }
  }

  .map {
    height: calc(100vh - 400px) !important;
  }

  .cursor-move {
    cursor: move;
  }
</style>

<style>
  .modal-card .b-table .has-sticky-header {
    height: calc(100vh - 445px) !important;
  }

  .leaflet-tooltip-pane {
    font-size: 14px;
  }

  .leaflet-tooltip {
    opacity: 1 !important;
  }

  .ungrouped-row a[role='button'] {
    display: none;
  }
</style>
